.site-header {
    @extend .position-fixed,
    .bg-white,
    .w-100;
    top: 0px;
    left: 0px;
    z-index: 97;
    transition: all .5s;
    .header-ele {
        @extend .d-flex,
        .justify-content-between;
    }
    .site-logo {
        @extend .order-1;
        padding: 1.5rem 0;
        transition: all .5s;
        @include media-breakpoint-down(xs) {
            padding: 1.5rem 0;
            img {
                max-width: 170px;
            }
        }
    }
    .header-nav-wrap {
        .mob-nav-bg {
            background: rgba(0, 0, 0, .5);
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 98;
            visibility: hidden;
            opacity: 0;
            transition: all .5s;
            &.show {
                visibility: visible;
                opacity: 1;
            }
            @include media-breakpoint-up(lg) {
                display: none;
            }
        }
        .header-nav {
            @extend .h-100;
            a {
                &:hover {
                    @extend .text-decoration-none;
                }
            }
            .mob-menu-close-btn {
                @extend .btn,
                .btn-light,
                .d-block,
                .d-lg-none,
                .m-3;
            }
            ul {
                @extend .list-unstyled,
                .m-0,
                .p-0;
            }
            &>ul {
                @extend .d-lg-flex,
                .h-100;
                &>li {
                    &>a {
                        font-family: 'daxmedium';
                        font-size: 1.3rem;
                        @extend .h-100,
                        .align-items-end,
                        .d-lg-flex;
                        padding: 2rem 1rem;
                        color: #666666;
                    }
                    @include media-breakpoint-up(lg) {
                        &:hover>a,
                        &.active>a {
                            background-color: $theme-color;
                            color: #FFF;
                        }
                    }
                    .header-nav-dd-wrap {
                        position: absolute;
                        left: -1rem;
                        top: 100%;
                        display: none;
                        right: -1rem;
                        padding: 1rem;
                        background-color: rgba(255, 255, 255, .95);
                        @extend .border-top,
                        .border-primary;
                        h4,
                        ul {
                            @extend .my-3;
                        }
                        h4 {
                            @extend .text-primary;
                            font-size: 1.25rem;
                        }
                        ul {
                            li {
                                @extend .my-1;
                                a {
                                    color: #666666;
                                    &:hover {
                                        @extend .text-primary;
                                    }
                                }
                            }
                        }
                    }
                    @include media-breakpoint-up(lg) {
                        &:hover .header-nav-dd-wrap {
                            display: block;
                        }
                    }
                }
            }
        }
        .mob-menu-toggler {
            @extend .d-block,
            .d-lg-none,
            .btn,
            .btn-primary;
            cursor: pointer;
            //color: #b99756;
        }
    }
    &.has-scrolled {
        transition: all .5s;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .2);
        .site-logo {
            padding: 1rem 0;
        }
    }
    @include media-breakpoint-down(lg) {}
    @include media-breakpoint-down(md) {
        .header-nav-wrap {
            display: flex;
            align-items: center;
            .header-nav {
                background-color: white;
                position: fixed;
                left: -300px;
                top: 0px;
                bottom: 0px;
                width: 100%;
                max-width: 280px;
                overflow: hidden;
                overflow-y: auto;
                transition: all .5s;
                z-index: 100;
                &.show {
                    left: 0px;
                }
                ul {
                    li {
                        a {
                            display: block;
                            padding: .7rem 1rem;
                        }
                        .header-nav-dd-wrap a {
                            padding: .2rem .5rem;
                        }
                    }
                }
                &>ul {
                    height: auto !important;
                    &>li {
                        &.has-submenu {
                            &>a {
                                position: relative;
                                &:after {
                                    position: absolute;
                                    right: 1rem;
                                    top: 50%;
                                    transform: translateY(-50%) rotate(-45deg);
                                    content: "";
                                    width: .8rem;
                                    height: .8rem;
                                    display: block;
                                    border-width: 0 3px 3px 0;
                                    border-style: solid;
                                    border-color: #666;
                                    transition: all .5s;
                                }
                                /* &:hover {
                                    &:after {
                                        border-color: #FFF;
                                    }
                                } */
                            }
                            &.opened {
                                &>a {
                                    color: #FFF;
                                    background-color: $theme-color;
                                    position: relative;
                                    &:after {
                                        transform: translateY(-75%) rotate(45deg);
                                        border-color: #FFF;
                                    }
                                }
                            }
                            .header-nav-dd-wrap {
                                position: static;
                                border: 0px !important;
                                //display: block;
                            }
                        }
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(xs) {
        .header-nav-wrap {
            .header-nav {
                left: -100%;
                max-width: inherit;
            }
        }
    }
}