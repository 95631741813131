html {
    line-height: 1.7;
    /* @include media-breakpoint-down(lg) {
        font-size: 14px;
        line-height: 1.5;
    } */
}

body {
    background-color: #ededed;
    font-family: 'daxregular';
    //font-size: 1.1rem;
    font-size: 1rem;
    overflow-x: hidden;
}

body,
.table {
    color: #666;
}

.mw-1600 {
    max-width: 1600px;
}

.mw-1400 {
    max-width: 1400px;
}

.mw-1200 {
    max-width: 1200px;
}

.mw-800 {
    max-width: 800px;
}

.mw-400 {
    max-width: 400px;
}

.mw-500 {
    max-width: 500px;
}

a {
    transition: all .5s;
}

img {
    max-width: 100%;
    &.embed-responsive-item {
        object-fit: cover;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @extend .text-primary;
    font-family: 'daxmedium';
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-bottom: 1.5rem;
}

.btn {
    @extend .rounded-0;
}


/* .btn-lg {
    font-size: 1.5rem;
} */

.btn-white {
    background-color: #FFF;
    &:hover,
    &:focus:hover,
    &:focus {
        @extend .bg-primary,
        .text-white;
    }
}

.pagination {
    margin: 0 -2px;
    li {
        margin: 2px;
        a {
            @extend .rounded-0,
            .border-0,
            .bg-primary,
            .text-white;
        }
        &.active {
            a {
                background-color: #b59855 !important;
            }
        }
    }
}

.border-style {
    &:after {
        @extend .d-block,
        .mt-2,
        .bg-primary;
        content: '';
        width: 50px;
        height: 3px;
    }
}

.back-to-top {
    position: fixed;
    display: none;
    right: 5%;
    bottom: 5%;
    z-index: 11;
    a {
        @extend .bg-primary;
        display: block;
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 20px;
        color: #99a9b5;
        border-radius: 5px;
        transition: all .5s;
        &:hover {
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
        }
        &:after {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            border-style: solid;
            border-width: 3px 3px 0 0;
            border-color: #212529;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -30%) rotate(-45deg);
        }
    }
}

.awards-wrap {
    margin-top: 2rem;
    h3 {
        color: #b59855 !important;
    }
    hr {
        border-color: #b59855;
    }
}


/* .am-content-sidebar-wrap {
    @extend .d-md-flex,
    .justify-content-between,
    .py-3;
    .am-content-section,
    .am-sidebar {
        flex: 1;
    }
    .am-content-section {}
    .am-sidebar {
        width: 27%;
        max-width: 360px;
        @extend .bg-white,
        .p-4;
    }
} */

.am-content-section,
.am-sidebar {
    @extend .my-5;
}

.am-sidebar {
    @extend .bg-white,
    .p-4;
    min-height: calc(100% - 6rem - 1.5rem - 2.6875rem);
}

.sidebar-widget {
    h4 {
        @extend .border-bottom,
        .border-primary,
        .mb-3;
    }
    p {
        @extend .my-3;
    }
    ul {
        @extend .list-unstyled,
        .m-0,
        .p-0;
        li {
            a {
                @extend .text-secondary,
                .d-block;
                padding: 2px 0;
                &:hover {
                    @extend .text-decoration-none,
                    .text-primary;
                }
            }
        }
    }
    &+& {
        @extend .mt-4;
    }
}

.page-top-content {
    @extend .text-center,
    .mt-5;
    p {
        //font-size: 1.4rem;
        font-size: 1.2rem;
    }
}

.page-bottom-buttons {
    .btn-white {
        @extend .my-2;
    }
}

.vision-banner {
    @extend .mt-5;
    .container-fluid {
        @extend .position-relative;
    }
    .vision-banner-content {
        @extend .position-absolute,
        .p-4;
        max-width: 400px;
        width: 40%;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
        p {
            @extend .mb-0;
        }
        @include media-breakpoint-down(sm) {
            position: static !important;
            transform: none;
            max-width: inherit;
            width: 100%;
            background-color: white;
        }
    }
}

.mission-banner {
    @extend .mt-5;
    .container-fluid {
        @extend .position-relative;
    }
    .mission-banner-content {
        @extend .position-absolute,
        .p-4;
        //max-width: 400px;
        width: 52%;
        left: 1rem;
        top: 50%;
        transform: translateY(-50%);
        p {
            @extend .mb-0;
        }
        @include media-breakpoint-down(md) {
            position: static !important;
            transform: none;
            width: 100%;
            background-color: #eff3f4;
        }
    }
}

.media-library-section,
.publications-section {
    a {
        &:hover {
            text-decoration: none;
            .bg-primary {
                background-color: #b59855 !important;
            }
        }
    }
}