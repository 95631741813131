$theme-color: #134a88;
$theme-colors: ( "primary": #134a88);
@import "supporting/fontawesome/fontawesome";
@import "supporting/fontawesome/brands";
@import "supporting/fontawesome/regular";
@import "supporting/fontawesome/solid";
@import "supporting/bootstrap/bootstrap";
@import "supporting/global";
@import "supporting/site-header";
@import "supporting/site-footer";
@import "supporting/news-section-grids";
@import "supporting/am-tabs-set";
@import "supporting/media-center-page";
@import "supporting/contact-page";
@import "supporting/almajdouie-group-page";
@import "supporting/almajdouie-values-page";
@import "supporting/almajdouie-governance-page";
@import "supporting/stock-images-page";
@import "supporting/stock-images-single-page";
@import "supporting/stock-videos-page";
@import "supporting/newsletter-page";
//@import "supporting/front-page";