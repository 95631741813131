.media-center-featured-news {
    @extend .position-relative,
    .d-block;
    img,
    h2:after {
        transition: all .5s;
    }
    h2 {
        @extend .position-absolute,
        .w-100,
        /* .p-4, */
        .py-3,
        .px-4,
        .text-white,
        .mb-0;
        left: 0px;
        bottom: 0px;
        font-size: 2rem;
        span {
            @extend .position-relative;
            z-index: 2;
        }
        &:after {
            @extend .d-block,
            .position-absolute,
            .bg-primary,
            .w-100,
            .h-100;
            content: '';
            opacity: .5;
            top: 0px;
            left: 0px;
        }
        @include media-breakpoint-up(xl) {
            /* padding: 3rem !important;
            font-size: 3rem; */
            font-size: 2rem;
        }
    }
    &:hover {
        img {
            transform: scale(1.1);
        }
        h2 {
            &:after {
                opacity: .7;
            }
        }
    }
}

.media-center-list-wrap {
    .media-center-box {
        @extend .bg-white,
        .mt-4;
        .media-center-box-thumb {
            @extend .h-100,
            .overflow-hidden;
            img {
                min-height: 100%;
                min-width: 100%;
                object-fit: cover;
                transition: all .5s;
            }
        }
        .media-center-box-content {
            @extend .p-4;
            .btn {
                /* min-width: 160px;
                font-size: 1.2rem; */
                min-width: 130px;
            }
        }
        &:hover {
            .media-center-box-thumb {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
}