.news-section-grids {
    margin-top: 2rem;
    .news-boxes-wrap {
        .news-box {
            @extend .bg-white;
            .news-box-thumb {
                @extend .h-100,
                .overflow-hidden;
                img {
                    min-height: 100%;
                    min-width: 100%;
                    width: auto;
                    height: auto;
                    object-fit: cover;
                    transition: all .5s;
                }
            }
            .news-box-content {
                @extend .p-4;
                h4 {
                    @extend .mt-0;
                }
                .btn {
                    /* min-width: 160px;
                    font-size: 1.2rem; */
                    min-width: 130px;
                }
            }
            &:hover {
                .news-box-thumb {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
    @include media-breakpoint-up(lg) {
        .news-boxes-wrap {
            .row {
                margin: 0px;
                [class*="col-"] {
                    padding: 0px;
                }
            }
            &>.row:nth-child(odd) {
                .news-box {
                    &>.row {
                        [class*="col-"]:first-child {
                            order: 1;
                        }
                    }
                }
            }
        }
    }
    @include media-breakpoint-down(md) {
        .news-box {
            margin: 1rem 0;
        }
    }
}

.btn-go-to-news-room {
    border: 0px;
    font-family: 'daxmedium';
    background-color: #c6d0db;
    color: #134a88;
    &:hover {
        background-color: #adbfd3;
        color: #134a88;
    }
}