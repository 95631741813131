.am-governance-cols-wrap {
    @extend .mt-4;
    .am-governance-col {
        @extend .my-4;
        .embed-responsive {
            @extend .position-relative,
            .mb-4;
            background-color: #1d2a4c;
            span {
                @extend .position-absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                font-size: 4rem;
                color: #f4f3fa;
            }
        }
    }
}