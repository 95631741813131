.newsletter-cols-wrap {
    @extend .mt-5;
    .newsletter-col {
        @extend .my-4,
        .bg-white;
        img {
            transition: all .5s;
        }
        .embed-responsive {
            &:before {
                padding-top: 138%;
            }
        }
        ul {
            @extend .list-unstyled,
            .px-4,
            .py-3,
            .m-0;
            li {
                @extend .position-relative,
                .my-1;
                padding-left: 7rem;
                label {
                    @extend .position-absolute,
                    .text-primary;
                    left: 0;
                    top: 0;
                }
            }
        }
        &:hover {
            img {
                transform: scale(1.1);
            }
        }
    }
}