.site-footer {
    @extend .border-primary,
    .mt-5,
    .py-3,
    .border-bottom;
    border-bottom-width: 0.75rem !important;
    color: #666666;
    .container-fluid {
        @extend .d-md-flex,
        .justify-content-between,
        .align-items-center;
    }
    .copyrights {
        p {
            margin: 1rem 0;
        }
    }
    .footer-links {
        @extend .my-3,
        .order-md-1;
        a {
            color: #666666;
            display: inline-block;
            margin: .5rem 1rem;
            &:hover {
                @extend .text-primary,
                .text-decoration-none;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        text-align: center;
    }
}