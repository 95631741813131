.am-values-cols-wrap {
    @extend .mt-5;
    .am-value-col {
        @extend .my-4;
        .am-value-col-icon {
            @extend .d-flex,
            .justify-content-center,
            .align-items-center,
            .flex-column,
            .bg-white,
            .mb-4;
            border-bottom: 3px solid #2799d6;
            //height: 230px;
            height: 170px;
            img {
                //max-height: 210px;
                max-height: 130px;
            }
        }
        &.am-care-col {
            .am-value-col-icon {
                border-color: #2799d6;
            }
        }
        &.am-accountability-col {
            .am-value-col-icon {
                border-color: #d44a14;
            }
        }
        &.am-transparency-col {
            .am-value-col-icon {
                border-color: #6fb739;
            }
        }
        &.am-commitment-col {
            .am-value-col-icon {
                border-color: #dbc10d;
            }
        }
        &.am-harmony-col {
            .am-value-col-icon {
                border-color: #e0388d;
            }
        }
    }
}