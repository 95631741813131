.stock-vidoes-list-wrap {
    @extend .mt-5;
    .stock-vidoe-box {
        @extend .bg-white,
        .mt-4;
        .stock-vidoe-box-thumb {
            @extend .h-100,
            .overflow-hidden;
            img {
                min-height: 100%;
                min-width: 100%;
                object-fit: cover;
                transition: all .5s;
            }
        }
        .stock-vidoe-box-content {
            @extend .p-4,
            .position-relative,
            .h-100;
            .stock-vidoe-box-btns {
                .btn {
                    @extend .d-flex,
                    .align-items-center,
                    .justify-content-center,
                    .h-100;
                }
            }
            @include media-breakpoint-up(lg) {
                padding-bottom: 5rem !important;
                .stock-vidoe-box-btns {
                    position: absolute;
                    left: 0px;
                    right: 0px;
                    bottom: 0;
                    //width: 100%;
                    margin: 1.5rem;
                }
            }
        }
        &:hover {
            .stock-vidoe-box-thumb {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
}