.am-tabs-set-wrap {
    .nav-tabs {
        @extend .border-0;
        margin-left: -.3rem;
        margin-right: -.3rem;
        li {
            font-family: 'daxmedium';
            padding-left: .3rem;
            padding-right: .3rem;
            margin: 0;
            a {
                @extend .border-0,
                .h-100,
                .d-sm-flex,
                .align-items-center,
                .justify-content-center,
                .bg-primary,
                .rounded-0,
                .text-white;
                //font-size: 1.4rem;
                //min-height: 5rem;
                min-height: 3.5rem;
                img {
                    max-height: 30px;
                }
                &.active {
                    @extend .bg-white,
                    .text-primary;
                }
            }
        }
    }
    .tab-content {
        @extend .bg-white;
    }
    .card {
        @extend .mb-2,
        .overflow-hidden;
        .card-header {
            @extend .p-0;
            .card-title {
                @extend .m-0;
                font-size: 1.3rem;
                a {
                    @extend .p-4;
                    &:not(.collapsed) {
                        @extend .bg-primary,
                        .text-white;
                    }
                }
            }
            &:last-child {
                background-color: #b59855 !important;
            }
        }
        .collapse,
        .collapsing {
            .fade {
                opacity: 1 !important;
            }
        }
        .card-body {
            @extend .p-0;
        }
    }
    &.home-tabs-set {
        margin-top: 2rem;
        .nav-tabs {
            li {
                &:last-child {
                    a {
                        background-color: #b59855 !important;
                        &.active {
                            background-color: #a58335 !important;
                        }
                    }
                }
            }
        }
        .tab-content {
            .tab-img-col {
                @extend .overflow-hidden;
                img {
                    min-height: 100%;
                    min-width: 100%;
                    width: auto;
                    height: auto;
                    object-fit: cover;
                }
            }
        }
        .card-soup {
            .card {
                &:last-child {
                    .card-header {
                        background-color: #b59855 !important;
                        img {
                            max-height: 20px;
                        }
                    }
                }
            }
        }
    }
}