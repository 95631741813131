.stock-imgs-single-cols-wrap {
    @extend .mt-5;
    .stock-img-single-col {
        @extend .my-4,
        .position-relative;
        min-height: calc(100% - 3rem);
        .embed-responsive {
            @extend .mb-4;
        }
        .btns-row {
            .row {
                margin: 0 -5px;
                [class*="col-"] {
                    padding: 0 5px;
                }
            }
            .btn {
                @extend .d-flex,
                .justify-content-center,
                .align-items-center,
                .h-100;
            }
        }
    }
    @include media-breakpoint-up(sm) {
        .stock-img-single-col {
            padding-bottom: 7rem;
            .btns-row {
                position: absolute;
                left: 0px;
                bottom: 0px;
                width: 100%;
            }
        }
    }
    @include media-breakpoint-up(md) {
        .stock-img-single-col {
            padding-bottom: 3.5rem;
            h3 {
                margin-bottom: 3rem;
            }
        }
    }
    @include media-breakpoint-up(xl) {
        .stock-img-single-col {
            padding-bottom: 3.5rem;
            h3 {
                margin-bottom: 1.5rem;
            }
        }
    }
}